
export const environment = {
      production: true,
      api_url: 'https://api-staging.integrate.io/',
      dashboardUrl: 'undefined',
      authToken: 'undefined',
      HUBSPOT_URL: 'https://gateway-staging.xplenty.com/send-hubspot-leads',
      SEGMENT_KEY: '9UNuKs9jM7Bq9jxfpwmytbOng4utBler',
      HUBSPOT_CONFIRMATION_WEBHOOK_URL: 'https://gateway-staging.xplenty.com/confirm-elt-lead',
      SOURCE_VERSION: '7ac5a610d7e58cdc4b4ae22773a65d4460d91c3b',
      SENTRY_ENV: 'staging',
      SENTRY_DSN: 'https://c1488dcd61abc554f8f2353651afbef6@o4507576419680256.ingest.us.sentry.io/4507808147898368',
};
